import { Fragment } from "react";
import { hasProperty } from "../../../utils/validate";
import Tool from "./atoms/Tool";
import ToolWithDropdown from "./atoms/ToolWithDropdown";

const ShoppingToolsComponent = ({ category, pageData, shoppingTools, title = null }) => {
	const { title: pageTitle, url: pageUrl } = pageData;
	const finalTitle = pageTitle + (title && title.length > 0 ? ` - ${title}` : '');

	const colSpan = shoppingTools ? 12 / shoppingTools.length : 3 //if null use 3

	return (
		<Fragment>
			{shoppingTools &&
				<div>
					<div className="row mt64px">
						<div className="center-align">
							<h4>{(!title || (title.length === 0)) ? 'Shopping Tools' : title}</h4>
						</div>
					</div>
					<div className="row shoppingTools mb64px">
						{shoppingTools.map((tool, i) => {
							return (
								<div
									className={`col s6 m${colSpan} l${colSpan}`}
									key={`col${i}`}
								>
									{tool.component === "singleLink" && hasProperty(tool, "link") && tool.link != null &&
										<Tool
											key={`tool${i}`}
											tool={tool}
											title={finalTitle}
											pageUrl={pageUrl}
											category={category}
										/>}
									{tool.component !== "singleLink" &&
										<ToolWithDropdown
											key={`tool${i}`}
											title={finalTitle}
											pageUrl={pageUrl}
											tool={tool}
											category={category}
										/>}
								</div>
							);
						})}
					</div>
				</div>}
		</Fragment>
	);
};

export default ShoppingToolsComponent;