import React from 'react';
import Link from "next/link";

type ProductLinkProps = { slug: string, title: string, productType: string, children: React.ReactElement | React.ReactNode, className?: string, };

const ProductLink = ({ className, productType, title, slug, children }: ProductLinkProps) => {
	return (
		<Link
			href={`/${productType}/[slug]`}
			as={`/${productType}/${slug}`}
			passHref
		>
			<a
				title={`${title}`}
				className={className || null}
			>
				{children}
			</a>
		</Link>
	);
}

export default ProductLink;
