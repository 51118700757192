import { useQueryClient } from "@tanstack/react-query";
import { ProductCardProps } from "./types";
import ProductCardLink from './link';
import { getQueryFn } from "../../../../utils/api/queryFunction";

import parse from "html-react-parser";

import LazyLoadComponent from "../../Image/LazyLoad";

const ProductCardWithButton = ({
	className,
	productType,
	slug,
	img,
	title,
	description,
	heading,
	hasButton,
	buttonCopy,
}: ProductCardProps) => {
	const queryClient = useQueryClient();

	return (
		<div className={`productCard ${className ? className : ""}`}
			onMouseEnter={async () => {
				await queryClient.prefetchQuery({
					queryKey: [productType, slug],
					queryFn: () => getQueryFn(`${productType}/${slug}`),
					staleTime: 10 * 1000, // only prefetch if older than 10 seconds
				})
			}}
		>
			<div className="productWrapper">
				<div className="productTitle">
					<h2>{title}</h2>
				</div>
				<div className="productImage">
					<LazyLoadComponent image={img} alt={title} blur="product-blur" />
				</div>
			</div>
			{heading && <div className="line" />}
			<div className="productHeading">
				<h2>{heading ? parse(heading) : ""}</h2>
			</div>
			<div className="productDescription">
				{description ? parse(description) : ""}
			</div>
			{hasButton && (
				<div className="productButton">
					<ProductCardLink
						productType={productType}
						slug={slug}
						title={`${title}`}
						className="waves-effect waves-light hButton btn-large buttonRed"
					>
						{buttonCopy}
					</ProductCardLink>
				</div>
			)}
		</div>
	);
}

export default ProductCardWithButton;