import LazyLoadComponent from "../../Image/LazyLoad";
import parse from "html-react-parser";
import { ProductCardProps } from "./types";

const ProductCardWithoutSlug = (
	{ title, className, img, heading, description }: ProductCardProps
) => {
	return (
		<a title={`${title}`}>
			<div className={`productCard ${className ? className : ""}`}>
				<div className="productWrapper">
					<div className="productTitle">
						<h2>{title}</h2>
					</div>
					<div className="productImage">
						<LazyLoadComponent
							image={img}
							alt={title}
							blur="product-blur"
						/>
					</div>
				</div>
				{heading && <div className="line" />}
				<div className="productHeading">
					<h2>{heading ? parse(heading) : ""}</h2>
				</div>
				<div className="productDescription">
					{description ? parse(description) : ""}
				</div>
			</div>
		</a>
	);
}

export default ProductCardWithoutSlug;
