import ProductCardLink from './link';

import { ProductCardProps } from './types';

import ProductCardWithButton from './product-card-with-button';
import ProductCardWithoutSlug from './product-card-without-slug';

// refactored due to the unwieldy giant component it used to be and the poor SEO.
const ProductCardComponent = (props: ProductCardProps) => {
	const {
		productType,
		title,
		slug,
		// is component specific - set by calling component - not passed from API
		hasButton = true,
	} = props;

	if (slug) {
		if (hasButton) {
			return (
				<div>
					<ProductCardWithButton
						{...props}
						hasButton={hasButton}
					/>
				</div>
			);
		} else {
			return (
				<ProductCardLink
					slug={slug}
					title={title}
					productType={productType}
				>
					<ProductCardWithButton
						{...props}
						hasButton={hasButton}
					/>
				</ProductCardLink>
			);
		}
	} else {
		// this block should not be necessary - even if a product is a subproduct it should always have a slug...
		console.error('no slug for product', title);

		return (
			<ProductCardWithoutSlug
				{...props}
			/>
		);
	}
};

export default ProductCardComponent;
